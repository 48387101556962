.demo-title-wrapper {
    width: 100%;
    margin-left: 74px;
    margin-top: 40px;
    margin-bottom: 15px;
    margin-right: 20px;
}

.demo-content-wrapper {
    margin-left: 74px;
    min-height: 1200px;
    margin-right: 20px;
    width: 100%;
}

#presentation-combo {
    width: 224px;
}

.demo-content-wrapper .factsheet {
    margin-top: 33px;
}

.logo-title svg, .demo-content-wrapper-title svg {
    fill: #3c4646 !important;
}

.tooltip-explain {
    font-size: 16px;
    line-height: 22px;
    margin: 18px;
}

.presentation-chooser-group {
    margin-top: 23px;
}

.presentation-rectangular {
    background: white !important;
    width: 280px;
    height: 160px;
    float: left;
    margin-right: 20px;
    margin-top: 10px !important;
}

.presentation-full-tile {
    display: flex;
    justify-content: flex-end;
    height: inherit;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    width: 225px;
}

.presentation-rectangular:hover {
    background: #e7e7e7 !important;
}

.presentation-tile-image {
    padding-right: 15px;
}

.presentation-tile-text {
    float:right;
    padding-bottom: 5px;
}

.presentation-tile-text-title {
    font-weight: bold;
}

.demo-title-wrapper div.logo-subtitle {
    font-size: 18px;
    line-height: 22px;
    float: left;
    padding-bottom: 20px;
    padding-top: 15px;
}

.demo-title-wrapper div.tags {
    float: right;
    padding-top: 7px;
}

.presentation-tile-text-description {
    padding-top: 5px;
    width: 200px;
}

div.demo-title-wrapper > .tags > button > .bx--assistive-text {
    width: 150px;
}

.demo-top {
    padding-top: 23px;
}

@media (max-width: 66rem) {
    .demo-title-wrapper {
        margin-left: 11px;
    }

    .demo-content-wrapper {
        margin-left: 11px;
    }
}

@media (max-width: 40rem) {

    .demo-title-wrapper div.tags {
        float: none;
        margin-top: 10px;
    }

    .demo-title-wrapper div.logo-title {
        float: none;
    }
}

@media print {
    header {
        visibility: hidden;
    }
}

.fs-web-container {
    max-width: 1200px !important;
    background-color: white;
    padding: 40px !important;
    margin: 0px !important;
}

.fs-web-container img {
    width: 100%;
}

.fs-web-container .fs-img {
    //border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 5px 0px;
}

@media (max-width: 66rem) {
    .fs-web-container {
        padding-left: 11px !important;
        padding-right: 20px !important;
    }
}

.fs-web-title {
    padding-top: 45px;
    padding-bottom: 60px;
}

.fs-web-stamp {
    width: 130px;
    height: 50px;
    line-height: 50px;
    background: #e9e9e9;
    color: #3C4646;
    text-align: center;
    vertical-align: middle;
    float: left;
}

.fs-web-container ul, .fs-web-container ol
{
    padding-left: 1rem !important;
}

.fs-web-container ul > li
{
    list-style-type: initial !important;
}

.fs-web-container ol > li
{
    list-style-type: decimal !important;
}

.fs-web-container pre {
    margin-top: 10px;
    background: #eee;
    border-radius: 20px;
    padding: 15px;
    font-size: 14px;
    line-height: 16px;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.fs-web-container sub {
    font-size: 12px;
    line-height: 12px;
}

.fs-web-container th {
    vertical-align: middle !important;
}

.fs-web-container h1 {
    text-transform: capitalize;
}

#toc-expanded {
    position: sticky; /* thank you for this CSS... :clap: */
    top: 52px;
}

#toc-collapsed {
    display: none;
    padding-bottom: 20px;
}

.nav-to-top {
    display: none;
}

@media (max-width: 66rem) {
    #toc-expanded {
        display: none;
    }

    #toc-collapsed {
        display: inherit;
    }

    .nav-to-top {
        display: initial;
    }

}

.rationale-toggle-container {
    padding-top: 20px;
    padding-left: 25px;
}

.rationale-content {
    color: #25A149;
}

.fs-web-container div.div-as-paragraph {
    padding-bottom: 15px;
}

// .bx--toggle-input:checked + .bx--toggle-input__label > .bx--toggle__switch::before {
//     background-color: #4A90E2 !important;
// }

.rationale-notes-legend {
    font-style: italic;
    color: #25A149;
}

.question {
    color: #00a2ed;
    font-weight: 600;
}

.sidebox {
    border: 2px solid green;
    padding: 1em;
}

.price {
    text-align: right;
    background-color: rgb(247, 247, 247);
    padding: 2em;
}

.dark {
    background-color: black !important;
    color: white !important;
}