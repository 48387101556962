#longTab {
    width: 17em;
}

figure {
    display: inline-block !important;
}

figure figcaption {
    font-style: italic;
    text-align: center
}