.stripe {
    padding-top: 50px;
}

.grey {
    background-color: #f3f3f3;
}

.home-logo-title {
    font-size: 36px;
    line-height: 36px;
    padding-bottom: 2rem;
}

.home-logo-video-button {
    padding-bottom: 40px;
}

.float-left {
    float: left;
    margin-right: 10px;
}

.section-title {
    font-size: 24px;
}

.last-section {
    padding-bottom: 90px;
}

.home-logo-text {
    font-size: 18px;
    line-height: 26px;
    max-width: 550px !important;
    padding-bottom: 2rem;
}

#content-separator {
    height: 1px;
    background-color: #BABABA;
    border: none;
    margin: 0px;
    margin-left: -22px;
}

.cell-wrapper {
    margin-left: 40px;
}

#fs360-img {
    float: right;
    padding-right: 40px;
}

.home-cards {
    margin-top: 24px;
    max-width: 900px;
}

@media (max-width: 66rem) {
    .cell-wrapper {
        margin-left: 10px;
    }
}

@media (max-width: 48rem) {
    #fs360-img {
        width: 100px;
        padding-right: 10px;
    }
}