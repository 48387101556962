ol.work-in-progress-list > li {
    padding-left: 10px;
}

.overview-img-container {
    max-width: 250px;
    position: relative;
    float: left;
    margin-right: 40px;
    width: 100%;
}

.overview-img-text {
    padding-bottom: 10px;
}

.overview-img-image img{
    width: 100%;
}

div.overview-images {
    padding-top: 30px;
}

div.overview-images > .bx--row > .bottom-padded {
    padding-bottom: 57px;
}

div.overview-images > .bx--row > .bx--col-md-4 {
    padding-left: 0px !important;
}

.overview-list > li {
    padding-left: 10px;
}

.overview-list > li:not(:first-child) {
    padding-top: 5px;
}

.overview-term-definition {
    font-size: 16px !important;
    line-height: 20px !important;
    color: #3c4646 !important;
}

.overview-term-definition + .bx--assistive-text {
    max-width: 200px;
}

.bx--tab-content {
    background-color: white;
}

li {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
}