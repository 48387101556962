.simple-title-wrapper {
    margin-left: 74px;
    margin-top: 50px;
    margin-bottom: 35px;
}

.simple-content-wrapper {
    min-height: 1000px;
    margin-left: 74px;
    padding-top: 1em;
    max-width: 800px;
    line-height: 25px;
}

.simple-content-wrapper a {
    font-size: 16px !important;
    color: #4A90E2 !important;
}

.simple-content-wrapper ul, .simple-content-wrapper ol
{
    padding-left: 1rem !important;
}

.simple-content-wrapper ul > li
{
    list-style-type: initial !important;
}

.simple-content-wrapper ol > li
{
    list-style-type: decimal !important;
}

@media (max-width: 66rem)  {
    .simple-title-wrapper {
        margin-top: 0px;
        margin-left: 11px;
        margin-right: 20px;
    }

    .simple-content-wrapper {
        margin-left: 11px;
        margin-right: 20px;
    }
}

.bx--tab-content {
    padding: 2em !important
}